<template>
  <div class="page blog-post">
    <div class="wrapper">
      <h1>About us</h1>
      <h2>"What Should I Play?" is a gaming polling and news app.</h2>
      <div class="post-cover">
        <img src="/images/image-10.jpg" alt="Graphic: What Should I Play | Source images: Black Pug Studio creative team" class="post-cover-image">
        <figcaption>Graphic: What Should I Play | Source images: Black Pug Studio creative team</figcaption>
      </div>
      <div class="post-content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      content: `
        <p>At <a href="https://blackpugstudio.com" target="_blank" rel="noopener">Black Pug Studio</a>, we've developed a unique web application <b>designed to empower the gaming community by giving them an influential voice in the gaming world</b>. Our innovative app, a pioneer in the gaming community, offers a platform for gamers to create engaging polls and involve their community in deciding their next gaming journey. This concept, born from a tangible need, is <b>strategically designed to cater to both streamers and gaming communities alike</b>.</p>
        <p>In addition to app development, we also serve as a publisher, <b>producing insightful articles centered on video games that we wholeheartedly believe enhance the gaming experience</b>. As we maintain a positive and constructive stance, we intentionally avoid games that do not meet our recommendation standards. If we don't have anything good to say about a game, we simply don't discuss it.</p>
        <p>Our groundbreaking gaming web app initiated its development journey in May 2022, with a full release anticipated for February 2024. As our project continues to expand and evolve, our dedicated team at <b>Black Pug Studio is fully committed to enhancing and refining the app</b>. We place immense value on feedback from our user community and consider every piece of advice and suggestion in our continuous quest for improvement and to provide an unparalleled gaming experience.</p>
      `,
    };
  },
};
</script>

<style scoped>
.page {
  padding: 40px 0 0;
}

.wrapper {
  width: 1056px;
}

.post-categories {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
}

.post-category {
  background-color: #fff;
  color: #F36;
  padding: 6px 12px;
  border-radius: 28px;
  font-weight: bold;
  font-size: 14px;
}

.post-views {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 12px;
  color: rgba(255, 255, 255, 0.69);
}

h1 {
  font-size: 40px;
  margin-bottom: 12px;
}

h2 {
  font-size: 20px;
  font-weight: 600;
}

.post-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 24px;
}

.post-meta {
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: uppercase;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.69);
}

.post-cover {
  margin-top: 32px;
  margin-bottom: 32px;
}

.post-share {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
}

.post-share .icon-button {
  background-color: #393652;
}

.post-author {
  border-right: 1px solid;
  padding-right: 8px;
}

.post-author span {
  color: #F36;
}

.post-cover-image {
  display: block;
  height: 560px;
  width: calc(100% + 36px);
  margin-left: -18px;
  object-fit: cover;
  margin-bottom: 12px;
}

.post-navigation {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  margin-top: 88px;
  border-top: 1px dashed rgba(255, 255, 255, 0.46);
}

.post-nav-item {
  width: 50%;
}

.post-nav-item.previous {
  padding-right: 48px;
  border-right: 1px dashed rgba(255, 255, 255, 0.46);
}

.post-nav-item.next {
  padding-left: 48px;
}

.post-box {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 24px 32px;
  background: #1F1D2C;
  gap: 24px;
  border-radius: 16px;
  color: rgba(255, 255, 255, 0.69);
}

.post-box .post-box-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #fff;
}

.post-box .icon-button {
  background: #393652;
}

.post-box .post-box-content {
  flex-grow: 1;
}

.post-content {
  font-size: 18px;
  line-height: 29.124px;
}

.post-content >>> h1,
.post-content >>> h2,
.post-content >>> h3,
.post-content >>> h4,
.post-content >>> h5,
.post-content >>> h6,
.post-content >>> p,
.post-content >>> img,
.post-content >>> figcaption,
.post-content >>> blockquote {
  display: block;
  margin-top: 0;
  margin-bottom: 32px;
}

.post-content >>> h1 {
  font-size: 32px;
}

.post-content >>> h2 {
  font-size: 24px;
}

.post-content >>> h3 {
  font-size: 20px;
}

.post-content >>> h4 {
  font-size: 18px;
}

.post-content >>> h5 {
  font-size: 16px;
}

.post-content >>> h6 {
  font-size: 14px;
}

.post-content >>> img {
  width: 100%;
}

.post-cover figcaption,
.post-content >>> figcaption {
  display: block;
  color: rgba(255, 255, 255, 0.69);
  font-size: 14px;
}

.post-content >>> figcaption {
  margin-top: -24px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.46);
}

.post-author-bio,
.post-content >>> blockquote {
  margin-left: 0;
  margin-right: 0;
  padding: 12px 0;
  font-size: 16px;
  line-height: normal;
  font-style: italic;
  border-top: 1px dashed rgba(255, 255, 255, 0.46);
  border-bottom: 1px dashed rgba(255, 255, 255, 0.46);
}

.post-author-bio {
  margin: 32px 0;
}

.post-content >>> a {
  color: #F36;
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .post-cover-image {
    height: 400px;
  }
}

@media screen and (max-width: 800px) {
  .post-nav-item.previous {
    padding-right: 24px;
  }
  .post-nav-item.next {
    padding-left: 24px;
  }
  .post-box {
    padding: 16px;
    gap: 16px;
  }
  .post-box .post-box-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 580px) {
  .post-meta {
    flex-direction: column;
    align-items: flex-start;
  }
  .post-author {
    border: none;
  }
  .post-nav-item.previous {
    padding-right: 12px;
  }
  .post-nav-item.next {
    padding-left: 12px;
  }
  .post-box .icon-button {
    display: none;
  }
}
</style>
